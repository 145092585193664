.onebody-text {
  font-weight: 700;
  font-size: 90px;
  color: #fff;
}

.hero-banner {
  height: 80vh;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.contain-an-image {
  background-size: contain !important;
}

/* about section */

.home-yt-iframe-wrapper {
  /* max-width: 100%;
  height: 500px; */
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.home-yt-iframe-wrapper > iframe {
  position: absolute;
  top: 0;
  height: 0;
  width: 100%;
  height: 100%;
}

/* services section */
.services-banner {
  height: 500px;
  background-size: cover;
  background-position: center;
}

.rotate {
  animation: rotation 4s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.services-marque-header {
  font-size: 80px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--heading-text-color);
}

.service-pic-frame {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: 80% 100%;
}

.service-title {
  font-size: 32px;
  font-weight: 600;
  color: var(--heading-text-color);
}

.service-time {
  border-bottom: 1px solid var(--body-text-color);
  display: inline-block;
  padding-bottom: 2px;
}

/* LOVA */
.lova-img-frame {
  width: 100%;
  height: 550px;
  background-position: top center;
  background-size: cover;
}

/* partner */

.partner-col-img-wrapper {
  height: 480px;
}

.partner-img-frame {
  height: 436px;
  background-size: cover;
  background-position: center;
}

.partner-small-img-frame {
  height: 127px;
  width: 70%;
  background-size: cover;
  background-position: center;
}

/* onebody-music */
.onebody-music-img-frame {
  position: relative;
  width: 100%;
  height: 600px;
  background-position: 100% 25%;
  background-size: cover;
  background-attachment: fixed;
}

.onebody-music-img-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 100;
}

.onebody-music-header {
  color: var(--pri-color);
  font-size: 34px;
  font-weight: 700;
}

.onebody-music-sub-header {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .onebody-text,
  .services-marque-header {
    font-size: 40px;
  }

  .service-marquee-wrapper > div {
    height: 3rem !important;
  }

  .service-title {
    font-size: 25px;
  }
}

@media screen and (max-width: 427px) {
  /* .hero-banner {
    height: 206px;
  } */

  .about-iframe-wrapper {
    height: 206px;
  }

  .services-banner {
    height: 231px;
  }

  .service-title {
    font-size: 20px;
  }

  .pastor-pic-frame {
    width: 200px;
    height: 200px;
  }
}
