.about-carousel-img {
  max-width: 450px;
  height: 450px;
  background-size: cover;
  background-position: center;
}

.about-hero-carousel {
  padding-right: 30px;
}

.about-iframe-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.about-iframe-wrapper > iframe {
  position: absolute;
  top: 0;
  height: 0;
  width: 100%;
  height: 100%;
}

.pastor-pic-frame {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  filter: grayscale();
  background-size: cover;
  background-position: top;
  position: relative;
}

@media screen and (max-width: 800px) {
  .pastor-pic-frame {
    width: 250px;
  }
}

.pastor-pic-frame:hover {
  filter: none;
  transition: all 2s;
}

.pastor-details {
  text-align: center;
  text-transform: capitalize;
  margin-top: 10px;
}

.pastor-details .name {
  font-weight: 500;
  color: #fff;
  font-size: 18px;
}

.about-timeline .vertical-timeline-element-content {
  padding: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
