.give-hero {
  width: 100%;
  height: 500px;
  background-position: center;
  background-size: cover;
  margin: 50px 0;
  position: relative;
  border-radius: 20px;
}

.give-hero-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 20px;
}

.give-hero-content > h2 {
  font-weight: 700;
  max-width: 370px;
}

.give-hero-content > p {
  max-width: 580px;
  font-weight: 400;
  font-size: 16px;
}

.payment-method-wrapper {
  margin-bottom: 50px;
}

.select-bar {
  width: 100%;
  border: 1px solid #fff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  padding: 10px;
  box-sizing: border-box;
}

.branch-option-wrapper {
  margin-top: 10px;
  width: 100%;
  background-color: #fff;
  /* padding: 10px 0;
  box-sizing: border-box; */
  color: #000;
  font-weight: 500;
}

.branch-option {
  padding: 10px 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.branch-option:hover {
  background-color: var(--pri-color);
  color: #fff;
}

.method-option {
  background-color: #fff;
  padding: 10px 20px;
  color: #000;
}

.acct-card {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
}

.acct-details > .bank-name {
  color: #000;
  font-weight: 400;
  font-size: 14px;
}

.acct-details > .acct-num {
  color: #000;
  font-weight: 600;
  font-size: 24px;
}

.acct-details > .acct-name {
  color: #000;
}

.acct-card .acct-copy-btn {
  width: 120px;
  height: 45px;
  background-color: var(--pri-color);
  color: #fff;
  font-weight: 600;
}
