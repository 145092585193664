/* the problem with this styling is that it might affect other ant-modals */
:where(.css-dev-only-do-not-override-1km3mtt).ant-modal,
:where(.css-1km3mtt).ant-modal {
  max-width: 750px !important;
  width: 100% !important ;
}

.pastor-modal-wrapper {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  column-gap: 20px;
}

.pastor-modal-pic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-size: cover;
  /* background-position: center; */
}

@media screen and (max-width: 500px) {
  .pastor-modal-wrapper {
    grid-template-columns: auto;
    column-gap: 0;
    row-gap: 10px;
    justify-items: center;
  }
}
