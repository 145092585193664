.live-header {
  margin: 50px 0 20px 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.live-header > .btn {
  padding: 10px 30px;
  background-color: #fff;
  color: var(--pri-color);
  font-weight: 500;
}

.live-header > .btn:hover,
.live-header > .active {
  background-color: var(--pri-color) !important;
  color: #fff;
  border: 0;
}
