@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --pri-bg: #000;
  --pri-color: #fd2cad;
  --body-text-color: #d6d6d6;
  --heading-text-color: #fff;
}
.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.v-hide {
  visibility: hidden;
}

body {
  background-color: var(--pri-bg);
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  background-color: var(--pri-bg) !important;
  color: var(--body-text-color) !important;
}

.pri-color {
  color: var(--pri-color);
}

.pri-btn {
  background-color: var(--pri-color);
  color: #fff !important;
  padding: 10px 25px;
  border-radius: 30px;
  font-size: 14px;
}

button {
  background-color: transparent;
  outline: none;
  padding: 0 !important;
  border: none;
}

button:focus {
  outline: none !important;
}

a {
  text-decoration: none !important;
  color: #000 !important;
}

.logo-wrapper {
  position: relative;
  z-index: 100;
}

.menu-wrapper {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1b1b1c;
  z-index: 10;
  overflow: hidden;
  overflow-y: scroll;
  padding-top: 100px;
  box-sizing: border-box;
}

.menu-wrapper li {
  gap: 0 20px;
  align-items: center;
  cursor: pointer;
  color: #535354;
  transition: all 1s;
  line-height: 3rem;
}

.menu-wrapper li:hover {
  color: #fff;
}

.menu-wrapper li > span:first-of-type {
  font-size: 18px;
}

.menu-wrapper li > span:last-of-type {
  font-size: 2rem;
  /* letter-spacing: 10px; */
}

h6 {
  font-weight: 700 !important;
}

@media screen and (min-width: 800px) {
  .menu-wrapper li > span:last-of-type {
    font-size: 8rem;
  }

  .menu-wrapper li {
    line-height: 7.5rem;
  }

  .menu-wrapper {
    padding-top: 5%;
  }
}

.section-title {
  text-transform: uppercase;
  font-weight: 700;
  color: var(--heading-text-color);
  font-size: 18px;
}

.section-link {
  color: var(--heading-text-color) !important;
  border-bottom: 1px solid var(--heading-text-color);
  padding-bottom: 2px;
  transition: all 2s;
}

.section-link path {
  transition: all 2s;
  stroke: var(--heading-text-color);
}

.section-link:hover,
.section-link:hover path {
  color: var(--pri-color) !important;
  stroke: var(--pri-color);
  border-bottom: 1px solid var(--pri-color);
}

/* .social-text-wrapper {
  column-gap: 30px;
} */

.social-text-wrapper > a:hover {
  color: var(--pri-color) !important;
}
/* Footer */
footer {
  color: #000;
  background-color: #fff;
  padding: 100px 0;
}

.footer-link-wrapper > div {
  margin-top: 16px;
}

.footer-link-wrapper > div > a:hover {
  color: var(--pri-color) !important;
}

/* mixlr page */

.onebody-mixlr-wrapper > iframe {
  width: 100%;
  height: 100vh;
}

section > header {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .section-header {
    font-size: 28px;
  }

  section > header img {
    width: 50px;
  }

  footer {
    padding: 50px 0;
  }
}
