.lova-banner {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.lova-cover-one {
  transform: rotate(26deg);
  position: absolute;
  left: 23%;
  bottom: 20px;
}

.lova-cover-two {
  transform: rotate(-10deg);
  position: relative;
  z-index: 1;
  bottom: 30px;
}

.book-option-modal {
  margin-top: 10px;
  color: black;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  width: 200px;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .lova-cover-two {
    position: static;
    transform: rotate(0);
  }
}
